export class Cookies {
  cookieName = 'confirmCookie';
  cookieExpiryDays = 7;
  showClass = 'show';
  hideClass = 'hide';

  cookiesContainer;
  confirmButton;

  init() {
    this.cookiesContainer = document.querySelector('.cookies');
    this.confirmButton =
      this.cookiesContainer?.querySelector('.cookies__button');

    if (!this.cookiesContainer) return;

    if (this.getCookie(this.cookieName)) {
      this.cookiesContainer.remove();
      return;
    }

    this.showCookies();

    if (this.confirmButton) {
      this.confirmButton.addEventListener('click', () => {
        this.confirmCookie();
      });
    }
  }

  setCookie(name, value, days) {
    const date = new Date();
    date.setDate(date.getDate() + days);
    document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
  }

  getCookie = (cookieName) => {
    let neededCookie = null;
    const cookies = document.cookie.split('; ');
    cookies.forEach((cookie) => {
      const parts = cookie.split('=');
      if (parts[0] === cookieName) {
        neededCookie = parts[1];
      }
    });

    return neededCookie;
  };

  showCookies() {
    setTimeout(() => {
      this.cookiesContainer.classList.add(this.showClass);
    }, 1000);
  }

  hideCookies() {
    this.cookiesContainer.classList.remove(this.showClass);
    this.cookiesContainer.classList.add(this.hideClass);

    setTimeout(() => {
      this.cookiesContainer.remove();
    }, 300);
  }

  confirmCookie() {
    this.hideCookies();
    this.setCookie(this.cookieName, 'true', this.cookieExpiryDays);
  }
}
